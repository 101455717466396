import * as statusMapper from "@/service/error_request_mapper.js";

export default class AuthAdminService {
  constructor(api) {
    this.api = api;
  }

  /** Retourne la liste des rôles avec l'héritage. */
  async getRoles() {
    let result = await this.api.getRoles();

    // return result.roles;
    return result;
  }

  /** Retourne les rôles pour l'entité "services". */
  async getRolesForServices() {
    let result = await this.api.getRolesForServices();
    // return result.services;
    return result;
  }

  async updateRolesForFunction(entity) {
    return this.api.updateRolesForFunction(entity).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(entity.id);
      converter.convert(error);
    });
  }

  /** Met à jour les rôles pour l'entité "services". */
  async updateRolesForServices(entity) {
    return this.api.updateRolesForServices(entity).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(entity.id);
      converter.convert(error);
    });
  }

  /** Retourne les rôles pour les fonctions. */
  async getRolesForFonctions() {
    let result = await this.api.getRolesForFonctions();

    return result;
  }

  /** Retourne les rôles de tous les collaborateurs ayant des rôles spécifiques. */
  async getDetailForAllCollaborater() {
    let result = await this.api.getDetailForAllCollaborater();
    return result;
  }

  /** Retourne les rôles d'un collaborateur. */
  async getDetailForCollaborater(userId) {
    let result = await this.api.getDetailForCollaborater(userId);
    return result;
  }

  /** Met à jour les rôles pour un collaborateur. */
  async updateRolesForCollaborater(entity, entityId) {
    return this.api
      .updateRolesForCollaborater(entity, entityId)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(entity.id);
        converter.convert(error);
      });
  }

  /** Obtient les rôles pour les clients */
  async getRolesForCustomer() {
    let result = await this.api.getRolesForCustomer();
    return result;
  }

  /** Met à jour les rôles pour un client. */
  async updateRolesForCustomer(roles) {
    return this.api.updateRolesForCustomer(roles).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404();
      converter.convert(error);
    });
  }
}
